<template>
  <main class="site-main">
    <!--캐러셀영역-->
    <div class="slider-1">
      <carousel :autoplay="true" :items="1" :loop="true" :nav="false" :autoplayTimeout="50000" :paginationEnabled="false"
      >
        <div class="landing_visual_area landing_visual_area_01 item">
          <div class="mv_text tex-box">
            <p class="mv_text_01">BMS Korea에서 운영하는</p>
            <p class="mv_text_02">의료인 전용 Portal Site, <span class="blo_480"></span><strong>BMSON</strong></p>
            <p class="mv_text_03">BMSON 의 모든 콘텐츠와 서비스는 회원가입이 완료된 보건의료전문가분들만 이용하실 수 있습니다.<br>
              지금 가입하시고 BMS 가 제공하는 다양한 콘텐츠와 서비스를 체험하세요</p>
            <button class="mv_btn" @click="login">로그인 / 회원가입</button>
          </div>
        </div>

        <div class="landing_visual_area landing_visual_area_02 item">
          <div class="mv_text tex-box">
            <p class="mv_text_01">BMS Korea에서 운영하는</p>
            <p class="mv_text_02">의료인 전용 Portal Site, <span class="blo_480"></span><strong>BMSON</strong></p>
            <p class="mv_text_03">BMSON 의 모든 콘텐츠와 서비스는 회원가입이 완료된 보건의료전문가분들만 이용하실 수 있습니다.<br>
              지금 가입하시고 BMS 가 제공하는 다양한 콘텐츠와 서비스를 체험하세요</p>
            <button class="mv_btn" @click="login">로그인 / 회원가입</button>
          </div>
        </div>
      </carousel>
    </div>
    <div class="landing_bn_area_top">
      <div class="landing_bn_area">
        <div class="landing_bn">
          <div class="bn_img"></div>
          <div class="bn_txt">
            <p class="landing_bn_tit">제품</p>
            <p class="landing_bn_exp">BMS 관련 허가 및 급여사항,<br/>학술자료, 브로셔 등 다양한 콘텐츠를 제공</p>
          </div>
        </div>
        <div class="landing_bn">
          <div class="bn_img"></div>
          <div class="bn_txt">
            <p class="landing_bn_tit">웨비나 & VOD</p>
            <p class="landing_bn_exp">BMS 개최 웨비나 라이브 사전등록 및<br/>참여 VOD 다시보기 등 콘텐츠 제공</p>
          </div>
        </div>
        <div class="landing_bn">
          <div class="bn_img"></div>
          <div class="bn_txt">
            <p class="landing_bn_tit">BMS 직원 방문 및 연락 요청</p>
            <p class="landing_bn_exp">BMS 제품 디테일 등을 위한<br/>BMS 직원 방문 및 연락 요청서비스</p>
          </div>
        </div>
      </div>
    </div>
    <div class="landing_bn_area_bottom">
      <div class="landing_bn_area">
        <div class="landing_bn">
          <div class="bn_img"></div>
          <div class="bn_txt">
            <p class="landing_bn_tit">BMS 소식</p>
            <p class="landing_bn_exp">BMS 뉴스 및 공지사항, 사회공헌활동,<br/>설문조사참여, 웹매거진 서비스</p>
          </div>
        </div>
        <div class="landing_bn">
          <div class="bn_img"></div>
          <div class="bn_txt">
            <p class="landing_bn_tit">의학정보요청 및 이상사례보고</p>
            <p class="landing_bn_exp">BMS 제품 관련 의학정보 요청 및<br/>이상사례보고 온라인 신청 서비스</p>
          </div>
        </div>
        <div class="landing_bn">
          <div class="bn_img"></div>
          <div class="bn_txt">
            <p class="landing_bn_tit">파이프라인</p>
            <p class="landing_bn_exp">BMS 글로벌의 신제품 연구 개발 동향 및<br/>관련 파이프라인 정보 제공</p>
          </div>
        </div>
      </div>
    </div>

  </main>
  <!--  <main class="site-main">-->
  <!--    <a :href="loginUrl">-->
  <!--      <div class="main_visual_area firstpage_mv"></div>-->
  <!--    </a>-->

  <!--    캐러셀영역-->

  <!--    <div class="vod_section firstpage_01">-->
  <!--      <div class="vod_section_d">-->
  <!--        <img src="@/assets/images/img_firstpage_01.png" alt="">-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div class="news_section firstpage_02">-->
  <!--      <img src="@/assets/images/img_firstpage_02.png" alt="">-->
  <!--    </div>-->

  <!--  </main>-->
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel';
import carousel from 'vue-owl-carousel'

export default {
  name: "noLogin",
  components: {
    carousel,
  },
  data() {
    return {
      isLogin: true,
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      let member = vm.$store.getters.getMember
      if (member) {
        next('/')
      } else {
        next()
      }
    })
  },
  created() {
    this.checkLogin()
  },
  computed: {
    getEnvHost() {
      return `?redirectUrl=${process.env.VUE_APP_REDIRECT_HOST ? window.location.origin : ''}${(this.$route.query.redirect ?? '').replace('main', '')}`
    },
    redirectUrl() {
      return `${window.location.origin}${this.$route.query.redirect ?? ''}`
    },
    loginUrl() {
      let url = new URL(process.env.VUE_APP_LOGIN_URL)
      url.searchParams.set('redirectUrl', this.redirectUrl)
      return url
    }
  },
  methods: {
    async checkLogin() {
      this.isLogin = await this.$store.dispatch('checkLogin')
    },
    login() {
      window.location.href = this.loginUrl

    }
  },
}
</script>

<style scoped>
.v-window__prev {
  display: none !important;
}

.v-window__next {
  display: none !important;
}
</style>
